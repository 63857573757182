.index-page-container {
    font-family: "Raleway";
    user-select: text;
    &:lang(es) {
        font-family: "Source Sans Pro","Helvetica Neue";
    }
    &:lang(vi) {
        font-family: "Source Sans Pro","Helvetica Neue";
    }
}
.index-header {
    position: fixed;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 10px 0px 17px 0px;
    top: 0px;
    background-color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 8px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    height: 8.5vh;
    min-height: 82px;
    width: 100%;
    z-index: 9;
}
.iheader-contnet {
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 0 4vw;
    height: 100%;
}
.index-logo {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.index-logo img {
    width: 225px;
    margin-bottom: -8px;
}
.index-menu {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: flex-end;
    justify-content: flex-end;
    font-family: 'Raleway';
    &:lang(es){
        font-family: "Source Sans Pro","Helvetica Neue";
    }
    &:lang(vi) {
        font-family: "Source Sans Pro","Helvetica Neue";
    }
}
.index-menu > .dq-lang-select {
    // display: none;
}
.index-collapse-icon {
    display: none;
}
.index-collapse-icon span {
    width: 48px;
    height: 6px;
    display: block;
    margin-top: 8px;
    border-radius: 5px;
    background-color: #ed6628;
}

//login button
.imenu-item {
    padding: 0 10px;
}
.imenu-item a {
    text-decoration: none;
    color: #6e6f70;
}
.imenu-item-active, .imenu-item a:hover {
    color: #001F3F !important;
}
.imenu-link {
    padding: 7px 13px;
    border-radius: 19px;
    background-color: #3F868A !important;
    color: #FFFFFF !important;
    display: flex;
    justify-content: center;
    cursor: pointer;
    min-width: 120px;
    font-weight: 700;
}
.Dropdown-control {
    border: 2px solid #3F868A !important;
    border-radius: 22px !important;
    min-width: 110px !important;
    color: #3F868A !important;
    padding: 6px 37px 6px 17px !important;
    background-color: #FFFFFF !important;
    font-weight: 700;
    font-size: 0.9rem !important;
    transition: all 200ms ease !important;
    font-family: "Raleway" !important;
    &:lang(es) {
        font-family: "Source Sans Pro", "Helvetica Neue" !important;
    }
    &:lang(vi) {
        font-family: "Source Sans Pro", "Helvetica Neue" !important;
    }
  }
  .Dropdown-arrow {
    border-color: #3F868A transparent transparent !important;
    border-width: 6px 6px 0 !important;
  }
  
  .is-open .Dropdown-arrow {
    border-color: transparent transparent #3F868A !important;
    border-width: 0 6px 6px !important;
  }
  .Dropdown-menu {
    background-color: #eeeeee !important;
    border: 1px solid #137c81 !important;
    border-radius: 10px !important;
    max-height: 300px !important;
    overflow-y: visible !important;
    font-family: "Raleway" !important;
  }
  
  .Dropdown-menu .Dropdown-group > .Dropdown-title{
    padding: 6px 10px;
    color: #137c81 !important;
  }
  
  .Dropdown-option {
    color: #137c81 !important;
  }
  
  .Dropdown-option:hover {
    color: #137c81 !important;
  }

.Dropdown-option:hover:first-child {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.Dropdown-option:hover:last-child {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

  .Dropdown-option.is-selected {
    color: #137c81 !important;
  }
.Dropdown-option.is-selected:first-child {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.Dropdown-option.is-selected:last-child {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}
  
.index-banner {
    position: relative;
    background: #1c3f53 url(../images/dqindex/world-map-hr.png) no-repeat center center;
    background-size: contain;
    color: #fff;
    margin-top: 70px;
    padding-bottom: 5%;
}
.index-banner2 {
    background: #B6E2DA no-repeat center center;
    background-size: cover;
}
.ibanner-img {
    visibility: hidden;
    width: 100%;
}

.ibanner-heading {
    position: absolute;
    width: 25%;
    left: 4vw;
    top: 40%;
    &:lang(ko) {
        width: 21%;
    }
    &:lang(ja) {
        width: 23%;
    }
}
.ibanner-heading-assess {
    position: absolute;
    color:#066f7f;
    width: 25%;
    left: 7%;
    top: 20%;
    &:lang(es){
        width: 20%;
    }
    &:lang(ko) {
        top: 25%;
    }
}
.ibanner-button {
    text-decoration: none;
    font-family: 'Raleway';
    font-size: 1.5vw;
    padding: 0.5vw 0;
    background-color: #001F3F;
    border-radius: 0.5vw;
    margin-top: 2vw;
    margin-left: 1vw;
    display: block;
    color:#FFFFFF;
    text-align: center;
    width: 63%;
    &:lang(es){
        font-family: "Source Sans Pro","Helvetica Neue";
    }
    &:lang(vi) {
        font-family: "Source Sans Pro","Helvetica Neue";
    }
}
.ibanner-video video{
    position: absolute;
    width: 65%;
    top: 15%;
    right: 0;
}
.ihead-title {
    font-family: 'Raleway';
    font-weight: bolder;
    // line-height: 8rem;
    font-size: 6.3rem;
    margin: 0;
    &:lang(ko) {
        font-size: 3.95vw;
    }
    &:lang(es) {
        font-family: "Source Sans Pro","Helvetica Neue";
    }
    &:lang(vi) {
        font-family: "Source Sans Pro","Helvetica Neue";
    }
    &:lang(ja) {
        font-size: 5vw;
    }
}
.ihead-subtitle {
    color: #3cc6f3;
    font-size: 1.55vw;
    margin: 0;
    &:lang(ja) {
        font-size: 1vw;
    }
}
.press-release-btn {
    position: absolute;
    top: 10%;
    right: 7%;
}
.press-release-btn a {
    display: block;
    background-color: #3cc6f3;
    text-decoration: none;
    text-align: center;
    border-radius: 10px;
    padding: 10px 20px;
    color: #fff;
    font-size: 1.5vw;
    font-weight: 500;
    min-width: 192px;
}
.press-release-btn a:hover {
    background-color: #0cc0e0;
}
.press-release-btn a:lang(ko) {
    padding: 9px 20px;
}
.ibanner-sm-txt {
    position: absolute;
    font-style: italic;
    font-size: .8vw;
    padding: 0 10px;
    bottom: 5%;
    left: 55%;
}


.index-section-container {
    margin: 0 auto;
    margin-top: 75px;
    max-width: 1140px;
    padding: 0 15px;
}
.isc-full-length {
    max-width: 1380px;
}
.isection-title {
    text-align: center;
    color: #ed6628;
    font-size: 2.2rem;
}
.isection-content {
    color: #808080;
    font-size: 1.3rem;
    &:lang(vi) {
        font-family: "Source Sans Pro","Helvetica Neue";
    }
    &:lang(es) {
        font-family: "Source Sans Pro","Helvetica Neue";
    }
    text-align: justify;
}
.isection-content a {
    color: #001F3F;
    font-weight: 500;
}
.isection-content img {
    max-width: 60%;
}

.skills-tracker {
    padding: 80px 0 60px;
}

.index-actions {
    padding-top: 13px;
}
.iactions-content {
    display: flex;
}
.iaction-col {
    display: block;
    position: relative;
    text-align: center;
}
.iaction-wizard {
    padding-top: 50px;
    width: 33%;
}
.iaction-wizard-content {
    margin: 0 30px;
    padding-bottom: 125px;
}
.iaction-wizard:after {
    content: "";
    height: 7px;
    background-color: #3cc6f3;
    position: absolute;
    top: -1px;
}
.iaction-wizard:before {
    content: '';
    position: absolute;
    top: -20px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    z-index: 1;
    transform: translate(-50%);
    left: 50%;
    background: #e6e7e9;
    border: 2px solid #3cc6f3;
}
.iaction-col h3 {
    font-size: 1.5rem;
    margin: 0;
}
.iaction-col h4{
    font-size: 1.3rem;
    margin: 0;
    margin-top: 0.6rem;
}
.iaction-col h3 {
    color: #3cc6f3;
}
.iaction-col h4 {
    color: #ed6628;
    min-height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.iaction-col p {
    font-size: 1rem;
    color: #808080;
}
.iaction-col img {
    width: 95%;
    margin: 0 auto;
}
.iaction-dct:before {
    background: #3cc6f3;
}
.iaction-dct:after {
    width: 50%;
    right: 0;
}
.iaction-co:after {
    width: 100%;
    left: 0;
}
.iaction-conf:after {
    width: 50%;
    left: 0;
}

.iaction-dct img {
    width: 50%;
    margin-top: 50px;
}
.iaction-co img {
    margin-top: 50px;
}
.iaction-btn a, button.iaction-btn {
    padding: 5px;
    color: #fff;
    border: 2px solid #082949;
    background-color: #082949;
    border-radius: 5px;
    text-decoration: none;
    width: 200px;
    margin: 19px auto;
    display: block;
    font-weight: 400;
    font-size: 1.2rem;
    cursor: pointer;
}
.iaction-btn a:hover, button.iaction-btn:hover {
    color: #082949;
    background: #ffff;
}

/*.digi-skills-row {
    display: flex;
    padding: 36px 0;
    justify-content: center;
}
.digi-skill {
    height: 150px;
    width: 250px;
    position: relative;
    margin: 0 9px;
    font-size: 1.3rem;
    color: #fff;
  }
  .digi-skill-content {
    position: absolute;
    text-align: center;
    padding: 70px 30px 0;
    top: -1px;
    z-index: 1;
  }
  .digi-skill-content span {
      font-family: 'Lemon/Milk';
  }
  .digi-skill::before, .digi-skill::after {
    content: "";
    position: absolute;
    border-left: 125px solid transparent;
    border-right: 125px solid transparent; 
  }
  .digi-skill::before {
    bottom: -58px;
    border-top: 58px solid; 
  }
  .digi-skill::after {
    top: -58px;
    border-bottom: 58px solid; 
  }

  .dl-cos { background: #3cc6f3; }
  .dl-cos::before { border-top-color: #3cc6f3; }
  .dl-cos::after { border-bottom-color: #3cc6f3; }
  .dl-cos .digi-skill-content {
      background: url(../images/dqindex/icons/child-online-safety-icon.png) top center no-repeat;
      background-size: 55px;
  }

  .dl-learn { background: #f8b143; }
  .dl-learn::before { border-top-color: #f8b143; }
  .dl-learn::after { border-bottom-color: #f8b143; }
  .dl-learn .digi-skill-content {
    background: url(../images/dqindex/icons/learning-icon.png) top center no-repeat;
    background-size: 94px;
}

  .dl-or { background: #b7d334; }
  .dl-or::before { border-top-color: #b7d334; }
  .dl-or::after { border-bottom-color: #b7d334; }
  .dl-or .digi-skill-content {
    background: url(../images/dqindex/icons/participation-icon.png) top center no-repeat;
    background-size: 62px;
}

  .dl-dit { background: #fff; color: #6e6f70; font-weight: bold; font-size: 1.6rem; }
  .dl-dit::before { border-top-color: #fff; }
  .dl-dit::after { border-bottom-color: #fff; }
  .dl-dit .digi-skill-content {
    background: url(../images/dqindex/dqindex-logo.png) center 18px no-repeat;
    background-size: 160px;
}

  .dl-wi { background: #6a8ec9; }
  .dl-wi::before { border-top-color: #6a8ec9; }
  .dl-wi::after { border-bottom-color: #6a8ec9; }
  .dl-wi .digi-skill-content {
    background: url(../images/dqindex/icons/digital-infra-icon.png) top center no-repeat;
    background-size: 55px;
}

  .dl-wb { background: #c91c6e; }
  .dl-wb::before { border-top-color: #c91c6e; }
  .dl-wb::after { border-bottom-color: #c91c6e; }
  .dl-wb .digi-skill-content {
    background: url(../images/dqindex/icons/wellbeing-icon.png) top center no-repeat;
    background-size: 71px;
}

  .dl-fe { background: #ea4e99; }
  .dl-fe::before { border-top-color: #ea4e99; }
  .dl-fe::after { border-bottom-color: #ea4e99; }
  .dl-fe .digi-skill-content {
    background: url(../images/dqindex/icons/female-icon.png) top center no-repeat;
    background-size: 45px;
}*/

.digi-skills-row {
    display: flex;
    padding: 3.1vw 0;
    justify-content: center;
}
.digi-skill {
    height: 8vw;/*116px; 148 */ 
    width: 16vw; /*200px; 240 */
    position: relative;
    margin: 0 .7vw;
    font-size: 1.3vw;
    color: #fff;
    opacity: .6;
    border-radius: 0.2vw;
  }
.digi-skill:hover {
    opacity: 1;
}
.digi-skill-content {
    position: absolute;
    text-align: center;
    padding: 4.5vw 2.2vw 0;
    top: -.7vw;
    z-index: 1;
    &:lang(ja) {
        top: -1.2vw;
    }
}
.digi-skill-content span {
    font-family: 'Lemon/Milk';
}
.digi-skill-hover {
    display: none;
    position: absolute;
    color: #888;
    z-index: 2;
    top: 5%;
    left: 80%;
    min-width: 20vw;
    text-align: left;
    background: #fff;
    padding: 15px;
    border: 1px solid;
    border-radius: 10px;
}
.hover-left {
    right: 80%;
    left: auto;
}
.digi-skill-hover div {
    margin-top: 3px;
}
.digi-skill-hover img {
    width: 50px;
    margin: 5px 3px;
}
.dl-cos:hover .digi-skill-hover, .dl-learn:hover .digi-skill-hover, .dl-or:hover .digi-skill-hover, 
.dl-wi:hover .digi-skill-hover, .dl-wb:hover .digi-skill-hover, .dl-fe:hover .digi-skill-hover {
    display: block;
}

.dl-cos .digi-skill-hover { border-color: #3cc6f3; }
.dl-learn .digi-skill-hover { border-color: #f8b143; }
.dl-or .digi-skill-hover { border-color: #b7d334; }
.dl-wi .digi-skill-hover { border-color: #6a8ec9; }
.dl-wb .digi-skill-hover { border-color: #c91c6e; }
.dl-fe .digi-skill-hover { border-color: #ea4e99; }

.digi-skill::before, .digi-skill::after {
    content: "";
    position: absolute;
    border-left: 8vw solid transparent;
    border-right: 8vw solid transparent; 
}
.digi-skill::before {
    bottom: -4.9vw;
    border-top: 5vw solid; 
  }
.digi-skill::after {
    top: -4.9vw;
    border-bottom: 5vw solid; 
  }

.dl-cos { background: #3cc6f3; }
.dl-cos::before { border-top-color: #3cc6f3; }
.dl-cos::after { border-bottom-color: #3cc6f3; }
.dl-cos .digi-skill-content {
      background: url(../images/dqindex/icons/child-online-safety-icon.png) top center no-repeat;
      background-size: 3.5vw;
  }

.dl-learn { background: #f8b143; }
.dl-learn::before { border-top-color: #f8b143; }
.dl-learn::after { border-bottom-color: #f8b143; }
.dl-learn .digi-skill-content {
    background: url(../images/dqindex/icons/learning-icon.png) top center no-repeat;
    background-size: 6.2vw;
}

.dl-or { background: #b7d334; }
.dl-or::before { border-top-color: #b7d334; }
.dl-or::after { border-bottom-color: #b7d334; }
.dl-or .digi-skill-content {
    background: url(../images/dqindex/icons/participation-icon.png) top center no-repeat;
    background-size: 4vw;
}

.dl-dit { 
    background: #fff; 
    color: #6e6f70; 
    font-weight: bold; 
    font-size: 1.6vw; 
    opacity: 1; 
    &:lang(ja) {
        font-size: 1.3vw;
    }
}
.dl-dit::before { border-top-color: #fff; }
.dl-dit::after { border-bottom-color: #fff; }
.dl-dit .digi-skill-content {
    background: url(../images/dqindex/dqindex-logo.png) center 1.5vw no-repeat;
    background-size: 9.5vw;
}

.dl-wi { background: #6a8ec9; }
.dl-wi::before { border-top-color: #6a8ec9; }
.dl-wi::after { border-bottom-color: #6a8ec9; }
.dl-wi .digi-skill-content {
    background: url(../images/dqindex/icons/digital-infra-icon.png) top center no-repeat;
    background-size: 3.5vw;
}

.dl-wb { background: #c91c6e; }
.dl-wb::before { border-top-color: #c91c6e; }
.dl-wb::after { border-bottom-color: #c91c6e; }
.dl-wb .digi-skill-content {
    background: url(../images/dqindex/icons/wellbeing-icon.png) top center no-repeat;
    background-size: 4.4vw;
}

.dl-fe { background: #ea4e99; }
.dl-fe::before { border-top-color: #ea4e99; }
.dl-fe::after { border-bottom-color: #ea4e99; }
.dl-fe .digi-skill-content {
    background: url(../images/dqindex/icons/female-icon.png) top center no-repeat;
    background-size: 2.9vw;
}

@media only screen and (max-width: 768px) {
    .index-banner2 {
        background: #ADDED3 no-repeat center center;
    }
    .ihead-title{
        font-size: 1.5rem;
    }
    .index-header {
        height: 60px;
        min-height: 8vh;
        height: auto;
    }
    .ibanner-video video {
        // top:0%;
        width: 65%;
    }
    .iheader-contnet {
        padding-right: 1vw;
    }
    .index-logo img {
        width: 171px;
    }
    .iheader-contnet {
        flex-direction: column;
    }
    .index-collapse-icon {
        display: block;
        margin-right: 20px;
    }
    .index-menu {
        padding-top: 18px;
        flex-direction: column;
        align-items: center;
        row-gap: 5px;
        max-height: 65em;
        display: visible;
        transition: max-height 1s ease-in-out;
    }
    .Dropdown-menu{
        overflow: visible !important;
    }
    .index-menu.collapsed {
        display: none;
        max-height: 0;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    }
    .index-menu > .dq-lang-select {
        display: block;

    }
    .index-menu > .imenu-item > .dq-lang-select {
        display: none;
    }

    .imenu-item {
        font-size: 1rem;
        text-align: center;
        padding: 0 2vw;
    }

    .imenu-language {
        font-size: 1rem;
        text-align: center;
    }
    .imenu-link {
        padding: 4px 5px;
    }
    .index-banner {
        margin-top: 58px;
        width: 100vw !important;
    }
    .ibanner-heading {
        top: 38%;
    }
    .ihead-subtitle {
        font-size: 2.8vw;
    }
    .index-section-container {
        margin-top: 5vh;
        padding: 0 5vw;
    }
    .isection-title {
        font-size: 7vw;
    }
    .isection-content {
        font-size: 4.8vw;
    }
    .skills-tracker {
        padding: 9vw 0 5vw;
    }
    .digi-skills-row {
        padding: 4.3vw 0;
    }
    .digi-skill {
        height: 18vw;
        width: 28vw;
        margin: 0 1vw;
        font-size: 2.6vw;
        border-radius: 0.2vw;
    }
    .digi-skill-content {
        padding: 7vw 2.2vw 0;
        top: -.1vw;
    }
    .digi-skill-hover {
        top: 105%;
        right: 0;
        left: 0;
        padding: 8px;
        border-radius: 8px;
    }
    .digi-skill-hover img {
        width: 40px;
        margin: 0px 1px;
    }
    .digi-skill::before, .digi-skill::after {
        border-left: 14vw solid transparent;
        border-right: 14vw solid transparent; 
    }
    .digi-skill::before {
        bottom: -6.9vw;
        border-top-width: 7vw; 
    }
    .digi-skill::after {
        top: -6.9vw;
        border-bottom-width: 7vw; 
    }
    .dl-dit {
        font-size: 3.3vw;
    }

    .dl-cos .digi-skill-content { background-size: 6vw; }
    .dl-learn .digi-skill-content { background-size: 10vw; }
    .dl-or .digi-skill-content { background-size: 7vw; }
    .dl-dit .digi-skill-content { background-size: 18vw; }
    .dl-wi .digi-skill-content { background-size: 6vw; }
    .dl-wb .digi-skill-content { background-size: 7.5vw; }
    .dl-fe .digi-skill-content { background-size: 4.5vw; }

    .iactions-content {
        display: block;
    }
    .iaction-col {
        width: 100%;
    }
    .iaction-btn a, button.iaction-btn {
        margin-top: 15px;
        margin-bottom: 50px;
        font-size: 1.1rem;
    }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
.ibanner-heading-assess{
    top:23% !important;
}
}