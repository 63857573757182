.ilabel-blue {
    text-align: center;
    color: #001F3D;
    font-weight: 500;
    margin-top: 2% !important;
}

.ibtn-assess a {
    font-family: 'Raleway';
  font-weight: bold;
    text-align: center;
    padding: 6px;
    &:lang(es){
        font-family: "Source Sans Pro","Helvetica Neue";
    }
    &:lang(vi) {
        font-family: "Source Sans Pro","Helvetica Neue";
    }
}

.dc-types-container {
    display: flex;
    align-items: end;
    font-size: 0.9rem;
    font-weight: 500;

    &:lang(ja) {
        font-size: 0.5rem;
    }

    &:lang(es) {
        font-size: 0.5rem;
    }
}

.dc-types-container ul {
    font-size: 1.1rem;
    padding: 5px;
    padding-bottom: 0;
    margin: 0;
    padding-left: 25px;
    text-align: left;
    margin-top: -52px;

    &:lang(ko) {
        margin-top: 1px;
    }
    &:lang(vi) {
        margin-top: 1px;
    }
}

.dc-types-container li {
    padding: 10px 0;
}

.dc-types-bars-title {
    margin-bottom: 4%;
    display: flex;
    flex-direction: column;
    text-align: right;
    white-space: nowrap;

    &:lang(ko) {
        margin-bottom: 5%;
    }

    &:lang(es) {
        margin-bottom: 5.5%;
    }

    &:lang(ja) {
        margin-bottom: 5%;
    }

    &:lang(th) {
        margin-bottom: 4%;
    }

    &:lang(vi) {
        margin-bottom: 2%;
        margin-right: 1%;
    }

}

.dc-types-bars-title span {
    padding: 3% 8%;
    margin-top: 45%;

    &:lang(es) {
        padding: 0 5%;
        font-size: 13px;
    }

    &:lang(ja) {
        font-size: 13px;
    }

    &:lang(th) {
        font-size: 13px;
        padding: 3% 6%;
    }
    &:lang(vi) {
        margin-top: 21%;
        padding: 7% 8%;
    }

    }

.dc-types {
    display: flex;
    border-collapse: collapse;
    text-align: center;
    overflow-x: scroll;
}

.dc-type {
    border: 1px solid;
    min-width: 210px;
    width: 20%;
    padding-bottom: 2%;

    &:lang(es) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.dc-type h3 {
    font-size: 1.7rem;
    margin-top: 25px;
    font-family: 'Raleway';
    color: #001F3D;
    padding: 0 15px;
    height: 70px;
    margin-bottom: 15px;

    &:lang(ko) {
        font-weight: 600;
    }

    &:lang(ja) {
        font-weight: 600;
        font-size: 1.4rem;
    }

    &:lang(es) {
        padding: 0 8px;
        font-size: 1.2rem;
        width: fit-content;
        font-family: "Source Sans Pro", "Helvetica Neue";
    }
    &:lang(vi) {
        font-family: "Source Sans Pro", "Helvetica Neue";
    }
}

.dc-type-icon {
    margin-top: 9%;
    width: 70%;
    max-width: 150px;
    height: 24% !important;
}

.dc-type-bars {
    padding: 50px 0;
    margin-top: -24px;
    height: fit-content;
}

.dc-type-bar {
    max-width: 80% !important;
    margin: 0px;
    height: auto;
    opacity: 1;
    z-index: 2;
}

.dc-type-bars .bar-graph {
    display: flex;
    align-items: flex-start;
    margin-left: 30px;
    position: relative;
    justify-content: space-between;
    height: 11vh;

    &:lang(es) {
        margin-bottom: 30px;
    }

    &:lang(ja) {
        margin-bottom: 30px;
    }

    &:lang(ko) {
        margin-bottom: 30px;
    }


}

.bar {
    position: absolute;
    left: 0;
    height: 25%;
    width: 0;
    background-color: white;
    
  }


[data-aos="moveBar1"],
[data-aos="moveBar2"],
[data-aos="moveBar3"] {
  opacity: 0;
  transform: scaleX(0);
}

[data-aos="moveBar1"].aos-animate,
[data-aos="moveBar2"].aos-animate,
[data-aos="moveBar3"].aos-animate {
  opacity: 1;
  transform: scaleX(1);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

@keyframes moveBar1 {
    0% {
        width: 0;
    }

    100% {
        width: 39%;
    }
}

@keyframes moveBar2 {
    0% {
        width: 0;
    }

    100% {
        width: 25%;
    }
}

@keyframes moveBar3 {
    0% {
        width: 0;
    }

    100% {
        width: 25%;
    }
}

.dc-type-skills {
    color: #082949;
    min-height: 215px;

    &:lang(ja) {
        min-height: 223px;
    }

    &:lang(es) {
        min-height: 164px;
    }

    &:lang(ko) {
        min-height: 200px;
    }

    &:lang(th) {
        min-height: 170px;
    }
}

.dt-carefree {
    background-color: #F49DD0;
    border-radius: 15px;
    border: none;
    margin: 5px;
}

.dt-always-on {
    background-color: #e472ab;
    border-radius: 15px;
    margin: 5px;
    border: none;
}

.dt-digilog {
    background-color: #CDDB7B;
    border-radius: 15px;
    border: none;
    margin: 5px;
}

.dt-ordinary {
    background-color: #9cd9f3;
    border-radius: 15px;
    border: none;
    margin: 5px;
}

.dt-cautious {
    background-color: #f49b72;
    border-radius: 15px;
    border: none;
    margin: 5px;
}

.dc-type-bars-range {
    display: flex;
    justify-content: space-between;

    &:lang(es) {
        margin-top: -20px;
    }
}

.lines {
    display: flex;
justify-content: space-between;
}
.lines line{
    padding: 0 22px;
}

.dc-type-bars-range span {
    color: #001F3D;
    padding: 0 22px;

    &:lang(es) {
        font-size: 17px;
    }

    &:lang(ja) {
        font-size: 15px;
    }
}

.iassess-section {
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: space-around;
    font-size: 1.3rem;
    color: #001F3D;
    margin-bottom: 60px;
}

.iassess-section img {
    margin-right: 5%;
    width: 23%;
}

.iassess-section p {
    margin-top: 5%;
    line-height: 1.4;
    display: block;
    width: 40%;
}

.iassess-section .iassess-details-content {
    line-height: 1.4;
    width: 75%;
}

.iassess-section .iassess-details-image {
    margin-left: 14%;
    width: 60%;
}

.iassess-section .iassess-details-image img {
    width: 70%;
}

.iassess-section .iassess-details-content ol {
    padding-left: 15%;
    padding-right: 10%;
}

.iassess-section .iassess-details-content ol li {
    margin: 11px;
}

.iassess-details-content p {
    width: 90%
}

.full-length-container {
    width: 100%;
    max-width: unset;
    margin: 0;
    padding-top: 75px;
    height: 70vh;
}

.full-length-container .index-section-content {
    padding-bottom: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-grey-shade .isection-title {
    text-align: left;
    font-size: 2.5rem;
}

.container-grey-shade {
    background-color: #ffff;
    height: fit-content;
}

.container-beige-shade {
    background-color: #feebde;
    height: fit-content;
    padding-bottom: 2%;
}

.container-grey-shade .isection-assessment-image {
    float: left;
    margin-left: 20%;
    width: 10%;
}

.container-grey-shade .isection-assessment-image img {
    height: 45vh;
    width: 15vw;
    margin-left: -15rem;

}

.container-gr-shade {
    background-color: #F3F5F9;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
}

.container-gr-shade .index-section-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 38px;
}

.container-gr-shade .index-section-content .isection-image {
    width: 35%;
}

.container-gr-shade .index-section-content .isection-image img {
    width: 100%;

}

.container-gr-shade .index-section-content .isection-content {
    height: fit-content;
    text-align: left;
    width: 40%;
    margin-left: 5%;
}

.container-gr-shade .index-section-content .dc-scores-container {
    margin-top: 3%;
    width: 30%;
}

.container-gr-shade .index-section-content .dc-scores-container .dc-dropdown {
    color: #001F3D !important;
    width: 50%;
}

.container-bg-shade {
    background-color: #FFFFFF;
    height: fit-content;
    padding-top: 2%;
    padding-bottom: 20px;
}

.container-gn-shade {
    background-color: #A0D0D1;
    height: fit-content;
    padding-top: 3%;
}

.container-gn-shade .isection-title {
    color: #001F3D;
}

.container-gn-shade .isection-assess-sp .isection-content p {
    width: 80%;
    text-align: left;
    margin-bottom: 2%;
}

.iaction-col h4,
.iaction-col p {
    color: #001F3D;
}

.isection-assess-sp {
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 5%;
}

.isection-assess-sp .isection-content p {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: 0 auto;
    color: #001F3D;
    margin-bottom: 3%;
}

.isection-toolip {
    position: relative;
    font-weight: 500;
    color: #3cc6f3;
}

.tooltip-content {
    display: none;
    position: absolute;
    padding: 2vh;
    background: #fff url(../images/dqindex/dq-citizenship-wheel-small.png) no-repeat 2vh center;
    background-size: 15vh;
    border: 1px solid #ed6628;
    border-radius: 15px;
    font-size: 1rem;
    font-weight: normal;
    color: #808080;
    top: 40px;
    left: 0;
    min-width: 50vh;
    padding-left: 20vh;

}

.isection-toolip:hover .tooltip-content {
    display: block;
}

.dc-scores-container {
    // width: 30%;
    text-align: center;
    margin-bottom: 2vh;
    margin-right: 30px;
    padding: 2vh;
}

.dc-country-ddl-container {
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin: 0.7vh 3vh;
}

.dc-country-ddl-container::after {
    content: "\25bc";
    color: #00888B;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 1.75vh;
    pointer-events: none;
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 2rem;
}

.dc-country-ddl {
    border-radius: 10px;
    border: 1px solid #00888B;
    min-width: 30vh;
    padding: 1vh;
    color: #00888B;
    font-size: 1.7rem;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.vertical-line {
    border-left: 6px solid #00888B;
    height: 70vh;
    margin: 24px 10px 0px 72px;
}

.dc-scores {
    display: flex;
    flex-direction: row;
}

.dc-score-content {
    display: inline-block;
    font-family: 'Raleway';
    font-size: 6vh;
    width: 16vh;
    height: 15vh;
    color: #001F3D;
    border-radius: 1.5vh;
    margin: 1vh;
    padding-top: 5vh;
    line-height: 2.5vh;
    &:lang(es){
        font-family: "Source Sans Pro","Helvetica Neue";
    }
    &:lang(vi) {
        font-family: "Source Sans Pro","Helvetica Neue";
    }
}

.dc-score-content span {
    font-size: 1.7vh;
}

.dq-dc-chart {
    border-radius: 5px;
}

.dc-chart-content {
    height: 57vh;
    margin-left: 40px;
    width: 35vw;
    position: relative;
}


.dc-chart-vlegend {
    position: absolute;
    transform: rotate(270deg);
    color: #2d4b62;
    top: 45%;
    left: -85px;

    &:lang(ko) {
        left: -65px;
    }
}

.dc-chart-name {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 10px;
    color: #2d4b62;
}

.isection-assessments {
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
    width: fit-content;
    box-sizing: border-box;
}

.isection-assessments div {
    padding: 4% 3% 7% 3%;
    margin-bottom: 10px;
    margin: 40px;
    text-align: center;
    border-radius: 35px;
    width: 400px;
    height: 380px;
    color: white;
}
.isection-assessments-type1 {
    background-color: #00888B;
}

.isection-assessments-type2 {
    background-color: #045174;
}

.isection-assessments-type3 {
    background-color: #E87A00;
}

.isection-assessments img {
    object-fit: fill;
    padding: 3px;
    height: 44%;
}

.isection-assessments span {
    display: block;
    color: #fff;
    font-size: 1.8rem;
    font-family: "RalewaySemiBold";
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 22px;
    &:lang(es){
        font-family: "Source Sans Pro","Helvetica Neue";
    }
    &:lang(vi) {
        font-family: "Source Sans Pro","Helvetica Neue";
    }
}

.iassess-details {
    display: flex;
    padding-top: 8vh;
}

.iassess-details-ico,
.iassess-details-content {
    display: block;
}

.iassess-details-ico {
    padding-top: 35px;
    text-align: center;
    width: 40%;
}


.iassess-details-ico img {
    width: 75%;
}

.iaction-kits-content {
    width: 100%;
    margin: 0 auto;
}

.iaction-kits-col {
    padding: 0 10px;
    padding-top: 10px;
    width: 280px;
    margin: 10px;
    border: none;
    border-radius: 15px;
    position: relative;
    padding-bottom: 80px;
    text-align: center;
    justify-content: center;
    background-color: #D2ECED;
}

.iaction-kits-col p {
    text-align: center;
    margin-top: 1px;
    padding: 10px;
    height: 30%;
}

.iaction-kits-col img {
    height: 80px;
    width: auto;
    margin-top: 5%;
}

.iaction-kits-col ul {
    text-align: left;
    padding-right: 10px;
    color: #001F3D;
    margin-bottom: 25%;
}

.iaction-col .iaction-btn {
    display: block;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
}

.iaction-kits-col .iaction-btn a {
    width: 90%;
}
.dots {
    display: none;
    text-align: center;
    margin-top: 10px;
  }
  
  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
  }

  .mobile-slider-arrows {
    display: none; /* Hide the arrows by default on larger screens */
  }

@media only screen and (max-width: 768px) {
    .mobile-slider-arrows {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-top: 20px; /* Adjust this margin as needed */
      }
      .next-arrow {
        position: relative;
        top: -68vw; /* Adjust the vertical position as needed */
        /* Adjust the distance from the right edge of the screen */
        transform: translateY(-50%);
        background-color: transparent;
        color:#001F3D ;
        padding: 10px;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        z-index: 999; 
        font-size: 25px;
        left: 56vw;
        &:lang(ja) {
            left: 67vw;
        }
        &:lang(en) {
            left: 68vw;
        }
        &:lang(ko) {
            left: 70vw;
        }
      }
    .dc-type-skills {
        &:lang(es) {
            min-height: 139px;
        }
        &:lang(en) {
            min-height: 178px;
        }
        &:lang(ja) {
            min-height: 232px;
        }
    }
    .isection-assess-sp {
        padding-bottom: 25px;
        display: block;
    }
    .dots {
        display: block;
      }

    .isection-title {
        font-size: 1.5rem !important;
        text-align: center !important;
        margin-top: 0 !important;
    }

    .full-length-container {
        padding-top: 16px;
    }

    .full-length-container .index-section-content {
        padding-bottom: 50px;
        display: block;
    }

    .dots {
        display: block;
      }
    .container-grey-shade .index-section-content {
        padding-top: 0%;
        margin-top: 0%;
    }

    .container-beige-shade,
    .container-gn-shade {
        align-items: center !important;
        justify-content: center !important;
        width: 100vw !important;
    }

    .container-gr-shade .index-section-content .isection-image {
        width: 80%;
        height: 80% !important;
    }

    .container-gn-shade .isection-assess-sp .isection-content p {
        width: 100% !important;
    }

    .container-gr-shade {
        width: 100vw;
    }



    .container-gr-shade .index-section-content .isection-content {
        width: 100%;
        text-align: center !important;
        padding: 3% !important;
        margin-left: 1px !important;
    }

    .isection-assessments-type1 {
        border-radius: 0px;
    }

    .iassess-section {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .iassess-section p {
        width: auto;
        text-align: center;
    }

    .iassess-section img {
        width: 53%;
        margin-right: 0% !important;
    }

    .iassess-section .iassess-details-content {
        width: 90vw;
        margin-top: 0px;
    }

    .iassess-details-content p {
        margin-left: 0px;
        padding: 0 18px;
    }

    .container-gn-shade {
        padding-bottom: 65px;
    }

    .line1{
        padding: 0 47px !important;
    }
    .line2{
        padding: 0 47px !important;
    }

    .line3{
        padding: 0 22px !important;
    }
    .iassess-section .iassess-details-image {
        margin-left: 0% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .iassess-section .iassess-details-image img {
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .isection-assessments div {
        align-items: center;
        padding-bottom: 20px !important;
    }

    .isection-assess-sp .isection-content p {
        width: 100%;
    }

    .isection-assessments div,
    .iassess-details div,
    .iaction-kits-col {
        width: 100%;
        margin: 5px !important;
        height: 286px !important;
    }

    .isection-image img {
        margin-bottom: 2rem;
    }

    .iaction-kits-col .iaction-btn {
        position: relative;
    }

    .iaction-kits-col .iaction-btn a {
        margin-bottom: 25px;
    }

    .isection-assessments img {
        height: 25% !important;
    }

    .iaction-kits-col {
        padding-bottom: 20px;
        height: fit-content !important;
    }

    .vertical-line {
        display: none;
    }

    .dc-type {
        min-width: 300px;
    }

    .dc-types{
        margin-left: -35px;
    }
    .dc-country-ddl-container{
        margin: 0.7vh -2vh;
    }

    .dc-types-bars-title {
        padding-bottom: 36px;
        &:lang(ko) {
            padding-bottom: 44px;
        }
        &:lang(ja) {
            padding-bottom: 40px;
        }
        &:lang(es) {
            padding-bottom: 58px;
        }
        &:lang(en) {
            padding-bottom: 46px;
        }
        &:lang(vi) {
            padding-bottom: 18px;
        }
    }
    .dc-types-bars-title span {
        &:lang(en) {
            margin-top: 30%;
        }
    }

    .iassess-details-image .iaction-btn a{
        margin-left: 15px !important;
    }

    .container-gr-shade .index-section-content .dc-scores-container {
        width: 100%;
    }

    .container-gr-shade .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .tooltip-content {
        min-width: 37vh;
        margin: 0 auto;
        padding-top: 18vh;
        padding-left: 2vh;
        background: #fff url(/static/media/dq-citizenship-wheel-small.74ab46ac.png) no-repeat center 2vh;
        background-size: 15vh;
        top: 25px;
        left: -7vh;
    }

    .ifooter-content {
        text-align: center !important;
        margin-left: 1px !important;
    }


    .dc-chart-content {
        padding: 13px;
        margin-left: 1%;
        height: 40vh !important;
        width: 77vw !important;
        font-size: 18px !important;
    }

    .dc-chart-name {
        font-size: .9rem;
    }

    .dc-chart-vlegend {
        font-size: .7rem;
        left: -55px;

        &:lang(ko) {
            left: -40px;
        }
    }
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .index-banner2 {
        width: 100vw !important;
    }

    .ihead-title {
        font-size: 3.7rem;
    }

    .isection-assessments div {
        //margin: 72px;
        margin: 31px;
    }

    .isection-assessments img {
        height: 50%;
    }

    .iaction-kits-content {
        width: 78%;
        flex-wrap: wrap;
    }

    .iaction-kits-col{
        margin: 20px !important;
    }

    .isection-image img {
        margin-left: -30% !important;
    }

    .container-gr-shade .index-section-content .isection-content {
        width: 90%;
    }
    .isection-assessments {
        align-items: center;
        justify-content: center;
    }

    .full-length-container {
        //width: 134% !important;
        width: 100vw !important;
    }

    .container-gr-shade .index-section-content {
        flex-wrap: wrap;
        align-items: center !important;
        justify-content: center !important;
    }

    .container-gr-shade .index-section-content .isection-image img {
        width: 150% !important;
    }

    .dqindex-footer {
        width: 135% !important;
    }

    .vertical-line {
        height: 44vh;
    }
    //.isection-assess-sp .isection-content p {
    //    width: 58% !important;
    //}

    .dc-types{
        margin-left: 1% !important;
        overflow-x: scroll;
        width: 73% !important;
    }

    .dc-type{
        padding-bottom: 9% !important;
        min-width: 270px !important;
    }
    .dc-types-bars-title span {
        padding: 18% 8%;
        &:lang(ko) {
            padding: 48% 8%;
        }
        &:lang(es) {
            padding: 20% 8%;
        }
        &:lang(th) {
            padding: 17% 8%;
        }
    }
        .dc-types-bars-title{
        margin-left: 5% !important;
            &:lang(es) {
               margin-bottom: 8.5%;
            }
            &:lang(th) {
                margin-bottom: 7%;
            }
    }
    .dc-type-skills{
        &:lang(ja) {
            min-height: 249px;
        }
    }
    .container-bg-shade .index-section-content .isection-content{
        width: 100vw !important;
    }
    .dc-country-ddl-container {
        margin: 0.7vh 0vh;
    }
    .dc-country-ddl{
        min-width: 8vh;
    }
    .dc-score-content{
        font-size: 4.5vh;
        line-height: 2vh !important;
    }
    .dc-chart-content {
        height: 38vh !important;
    }
}

/*styles for 13-inch screens*/
@media screen and (min-width: 1024px) and (max-width: 1320px) {
    .ihead-title {
        font-size: 4.8rem;
    }
    .isection-assessments div {
      width: 320px !important;
    }

}
