.stats-container {
    padding: 50px 0px;
    min-height: 100%;
    width: 100%;
}

.stats-content {
    text-align: center;
}

.stats-title {
    font-size: 1em;
    color: #19174a;
    text-align: center;
}

.stats-score-section {
    background: #f5ecdf;
    height: 200px;
    margin: 15px 60px;
}

.stats-charts-section {
    width: 100%;
    padding: 4% 2%;
    height: auto;
    align-self: flex-start;
    text-align: center;
}

.stats-charts_btn_section {
    /*background: #f5ecdf;*/
    height: 32px;
    margin: 10px 40px;
}

.stats-charts-btn-container {
    display: flex;
    justify-content: space-between;
}

.stats-remarks-section {
    height: auto;
    margin: 20px;
}

.stats-remarks-item-container {
    min-height: 100px;
    border: 1px dashed #b4b4b4;
    margin: 10px;
    border-radius: 12px;
    padding-bottom: 15px;;
}

.stats-feedback-section {
    display: table;
    font-size: .7em;
    width: 100%;
    padding: 0 5px;
}

.stats-feedback-section ol {
    padding-left: 1.1rem;
}

.stats-feedback-section .row {
    /* display: table-row; */
}

.stats-feedback-section .row div {
    /* display: table-cell; */
    display: inline-block;
    padding: 5px;
}

.row .col-name {
    width: 66%;
}

.row .row-col-sm {
    width: 17%;
}

.stats-stregnths {
    color: #3cc6f3;
}

.stats-weaknesses {
    color: #dd4ef7;
}

.stats-tips {
    color: gray;
}

.stats-tips ul {
    margin: 0;
    padding-left: 28px;
    font-size: 1.1em;
    font-family: "Source Sans Pro", "Helvetica Neue";
}

.stats-feedback-section span {
    background-color: #19174a;
    font-size: .7em;
    padding: 2px 5px;
    border-radius: 4px;
}

.stats-badges-progress-content {
    display: flex;
    font-size: .7em;
    color: #3cc6f3;
    padding: 0 7%;
    margin-bottom: 15px;
}

.stats-badges-progress-content div {
    margin: 5px;
}

.stats-badges-progress {
    position: relative;
    margin: 2% auto;
    background-color: #ddd;
    border-radius: 5px;
    width: 60%;
    height: 2.5vh;
}

.stats-badges-progress-bar {
    position: absolute;
    height: 100%;
    /* width: 20%; */
    border-radius: 5px;
    background-color: #3cc6f3;
}

.stats-badge-title {
    text-align: center;
    font-size: 1.4rem;
}

.stats-tmp-btn {
    background: #f8b143;
    height: 32px;
    width: 32px;
    border-radius: 32px;
}

.stats-chart img {
    width: 100%;
}

.stats-list-container {
    left: 0;
    width: 100vw;
    height: auto;
    padding: 10px;
}

.stats-list-title-section {
    font-size: 0.9rem;
    height: auto;
    display: flex;
}

.stats-list-title-section p {
    white-space: pre-wrap;
    margin: auto 0;
}

.stats-badge-item-detail {
    background-color: white;
    height: auto;
    left: 0;
    width: 100vw;
    overflow: hidden;
}

.stats-badge-item-close {
    max-height: 0;
    transition: max-height 0.8s cubic-bezier(0, 1, 0, 1);
}

.stats-badge-item-open {
    max-height: 600px;
    transition: max-height 0.8s ease-out;
}

.stats-badges-main-section {
    background-color: #e7e5e6;
    height: auto;
    padding: 10px 3%;
    display: flex;
    flex-wrap: wrap;
}

.badge-unearned {
    background: none !important;
}

.badge-unearned span {
    color: #fff !important;
}

.badge-unearned img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

.stats-badge-img {
    width: 16vw;
    min-height: 16vw;
}

.stats-badge-span {
    display: inline-block;
    font-size: 0.55rem;
    padding-left: 5px;
    padding-right: 5px;
    line-height: 1.4em;
    /*white-space: pre-wrap;*/
    /*overflow-wrap: break-word;*/
    /*word-wrap: break-word;*/
}

.stats-badge {
    margin: 1.5%;
    /* height: 32vw; */
    width: 30%;
    text-align: center;
    border: 1px solid white;
    border-radius: 10px;
    padding-bottom: 5px;
    padding-top: 10px;
}

.stats-badge-completed {
    background: #e1e7e2;
}

.stats-list-item-img {
    height: 50px;
}

