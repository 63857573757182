.balloons {
  position: absolute;
  height: 90%;
  width: 100%;
  bottom: 0;
  left: 0;
  pointer-events:none;
  touch-action:none;
}
.blns {
  overflow:hidden;
  position: relative;
  width: 100%;
  height: 100%;
}
.blns span {
  font-size: 0.9rem;
  padding: 15%;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-family: "Source Sans Pro", "Helvetica Neue";
}
.bln-clk {
  background-image: radial-gradient(ellipse farthest-corner at 45px 45px, #e8e8e8 0%, #cfcfcf 50%);
  transform: rotate(20deg);
  &:before {
    border-bottom-color: #cfcfcf !important;
  }
  span {
    color: #1a194c;
  }
}

.blns div {
  top: 101%;
  left: 40px;
  text-align: center;
  position: absolute;
  font-weight: 500;
  border-radius: 50%;
  pointer-events: all;
  touch-action: auto;
  z-index: 120;

  &[class^='bln-0'] {
    background-image: radial-gradient(ellipse farthest-corner at 45px 45px, #ffc637 0%, #f8b13d 50%);
    &:before { border-bottom-color: #f8b13d; }
    &:before {
      content: "";
      position: absolute;
      bottom: -10px;
      right: 50%;
      width: 0;
      height: 0;
      margin-right: -10px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 13px solid #f8b13d;
    }
    &:after {
      content: "〰";
      position: absolute;
      top: 101%;
      left: 48%;
      color: #cccccc;
      font-size: 22px;
      transform: rotate(50deg);
    }
  }
  &[class^='bln-1'] {
    background-image: radial-gradient(ellipse farthest-corner at 45px 45px, #3bc0f0 0%, #0075bc 50%);
    &:before { border-bottom-color: #0075bc; }
    &:before {
      content: "";
      position: absolute;
      bottom: -10px;
      right: 50%;
      width: 0;
      height: 0;
      margin-right: -10px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 13px solid #0075bc;
    }
    &:after {
      content: "〰";
      position: absolute;
      top: 101%;
      left: 48%;
      color: #cccccc;
      font-size: 22px;
      transform: rotate(50deg);
    }
  }
  &[class^='bln-2'] {
    background-image: radial-gradient(ellipse farthest-corner at 45px 45px, #f89640 0%, #c03940 50%);
    &:before { border-bottom-color: #c03940; }
    &:before {
      content: "";
      position: absolute;
      bottom: -10px;
      right: 50%;
      width: 0;
      height: 0;
      margin-right: -10px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 13px solid #c03940;
    }
    &:after {
      content: "〰";
      position: absolute;
      top: 101%;
      left: 48%;
      color: #cccccc;
      font-size: 22px;
      transform: rotate(50deg);
    }
  }
  &[class^='bln-3'] {
    background-image: radial-gradient(ellipse farthest-corner at 45px 45px, #cf85b8 0%, #a3509d 50%);
    &:before { border-bottom-color: #a3509d; }
    &:before {
      content: "";
      position: absolute;
      bottom: -10px;
      right: 50%;
      width: 0;
      height: 0;
      margin-right: -10px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 13px solid #a3509d;
    }
    &:after {
      content: "〰";
      position: absolute;
      top: 101%;
      left: 48%;
      color: #cccccc;
      font-size: 22px;
      transform: rotate(50deg);
    }
  }
  &[class^='bln-4'] {
    background-image: radial-gradient(ellipse farthest-corner at 45px 45px, #b0cb47 0%, #3d954b 50%);
    &:before { border-bottom-color: #3d954b; }
    &:before {
      content: "";
      position: absolute;
      bottom: -10px;
      right: 50%;
      width: 0;
      height: 0;
      margin-right: -10px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 13px solid #3d954b;
    }
    &:after {
      content: "〰";
      position: absolute;
      top: 101%;
      left: 48%;
      color: #cccccc;
      font-size: 22px;
      transform: rotate(50deg);
    }
  }
}
.bln-small {
  width: 118px;
  height: 124px;
}
.bln-medium {
  width: 140px;
  height: 155px;
}
.bln-large {
  width: 158px;
  height: 165px;
}