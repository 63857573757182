.backdrop {
    position: fixed;
    background-color: rgba(0,0,0,0.7);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 90;
}

.sidebar {
    position: fixed;
    width: 70%;
    max-width: 400px;
    height: 100%;
    top: 0;
    left: 0;
    background: #ffffff;
    z-index: 100;
    font-family: 'Source Sans Pro';
    overflow: hidden;
}

.usr {
    padding-left: 20px;
    font-size: 1rem;
    margin: 1.3rem;
    font-weight: bold;
}

.sidebar_close {
    transform: translateX(-100%);
    transition: transform 0.4s ease-out;
}

.sidebar_open {
    transform: translateX(0);
    transition: transform 0.4s ease-out;
}

.sidebar ul li {
    list-style: none;
    color: #1d1c1c;
    font-size: 1rem;
    padding: 14px 0;
    font-weight: normal;
}

.image {
    margin: 20px;
    height: 75px;
}

.header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 56px;
    padding: 0;
    background: #ffffff;
    z-index: 50;
    display: flex;
    justify-content: space-between;
}

.header img {
    float: right;
    margin: 1.5vh 2vh 0;
    height: 5vh;
    width: 7vh;
}

.toggle {
    padding: 6px 12px;
    cursor: pointer;
}

.toggle span {
    width: 48px;
    height: 6px;
    display: block;
    margin-top: 8px;
    border-radius: 5px;
}