.setting-container {
    padding: 56px 20px;
    min-height: 100%;
    width: 100%;
}

.dq-audience-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 10px 20px;
    margin: auto;
    width: 80vw;
    max-width: 700px;
    font-family: 'Source Sans Pro';
}


.post-quiz-section-dq-image {
    text-align: center;
    padding-top: 5vh;
}

.post-quiz-sub-title {
    font-size: 1.2rem !important;
    color: gray !important;
}

.post-quiz-label {
    font-style: italic;
    text-align: center;
    color: gray !important;
}

.audience-section-dq-image {
    text-align: center;
    padding-top: 9vh;
}

.audience-section-dq-image img, .post-quiz-section-dq-image img {
    height: 12vh;
    margin: 1vh auto;
}

.dq-audience-title {
    color: #19174a;
    text-align: center;
    font-size: 1.75rem;
    font-weight: 500;
    margin-top: 2vh;
    margin-bottom: 0;
}

.dq-audience-options {
    display: table;
    border-spacing: 3vh;
    width: 100%;
}

.dq-audience-options span {
    border: 2px solid #1fb2e7;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 500;
    padding: 2.5vh;
    min-width: 30%;
    border-radius: .6rem;
    text-align: center;
    color: gray;
    white-space: pre-wrap;
    display: table-cell;
    vertical-align: middle;
}

.dq-audience-options .selected {
    color: #fff;
    background-color: #1fb2e7;
}

.dq-audience-login {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 2vh;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
}

.dq-audience-login p {
    margin: 3px;
    color: gray;
}

.dq-audience-login a {
    /* padding: 5px 15px; */
    color: rgb(69, 165, 248) !important;
    font-size: 1.1rem;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.dq-dashboard-modal-login a {
    color: #f9631c;
    font-size: 1.1rem;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.dq-audience-login-blue {
    margin-top: 2vh;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
}
.dq-audience-login-blue a {
    padding: 5px 15px;
    color: #066f7f;
    font-size: 1.1rem;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.dq-options-submit {
    position: relative;
    margin-top: 6vh;
    text-align: center;
}

.dq-options-submit button {
    border: 2px solid #066f7f;
    border-radius: 10px;
    padding: 1.5vh 9vh;
    margin: auto;
    color: #066f7f;
    font-size: 1.6rem;
    background-color: #fff;
    font-weight: 600;
    cursor: pointer;
}

.dq-options-submit button:disabled {
    border-color: gray;
    color: gray;
    cursor: not-allowed;
}

.platforms-page {
    max-width: 450px !important;
}

.dq-platform-container {
    margin-top: 6vh;
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    row-gap: 30px;
    flex-wrap: wrap;
}

.dq-platform-container > div {
    aspect-ratio: 1/1;
    position: relative;
    display: flex;
    flex: 1;
}

.dq-platform-container .dq-platform-select {
    flex: 1;
    border: 2px solid #777;
    background-color: #3cc6f3;
    border-radius: 9px;
    font-size: 1.6rem;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
}

.dq-platform-container .dq-platform-select:disabled {
    background-color: darkgrey;
    cursor: not-allowed;
}

.dq-platform-buy {
    flex: 1;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #f8b13d;
    border-bottom-right-radius: 9px;
    border-top-left-radius: 9px;
    border-width: 2px 0 0 2px; /*top right bottom left*/
    cursor: pointer;
}

.dq-platform-buy:hover {
    background-color: #fca22d;
}

.dq-platform-lock-img {
    margin-top: 10px;
    height: 30px;
}

.dq-audience-container .dq-options-submit button {
    padding: 1.5vh 4vh;
}

.dq-btn-2 button {
    margin-bottom: 20px;
    width: 30vh;
    padding: 1.5vh 0;
    display: block;
}

.dq-app-footer {
    clear: both;
    width: 100%;
    font-family: 'Source Sans Pro';
    position: absolute;
    bottom: 20px;
    left: 0px;
}

.dq-guest-footer, .dq-app-footer {
    /* position: absolute;
    width: 100%;
    bottom: 3%;
    left: 0; */
    margin-top: 5vh;
    text-align: center;
}

.dq-dashboard-modal-login-footer{
    /* position: absolute;
    width: 100%;
    bottom: 3%;
    left: 0; */

    text-align: center;
}

.dq-footer-link {
    color: #1fb2e7;
    font-size: .8rem;
    font-weight: 500;
    margin: .8vh;
}

.dq-modal-contact-us-link {
    color: #1fb2e7;
    font-size: .8rem;
    font-weight: 500;
}

.dq-guest-footer span, .dq-app-footer span {
    color: #1fb2e7;
    font-size: .8rem;
}

.dq-copyright {
    margin-top: 1vh;
    text-align: center;
    font-size: .75rem;
    color: gray;
}

@media only screen and (max-width: 768px) {
    .dq-audience-title {
        font-size: 1.75rem;
    }

    .dq-audience-options {
        border-spacing: 0;
    }

    .dq-audience-options span {
        display: block;
        white-space: unset;
        padding: 1.5vh;
        margin: 2vh 0;
        font-size: 1rem;
    }

    .dq-platform-container {
        flex-direction: column;
    }

    .dq-platform-container > div {
        /*flex: unset;*/
        min-width: 150px;
        max-width: 150px;
        margin: 0 auto;
    }
}