.not-found-main-container {
    margin-top: 20vh;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 40vh;
}

.not-found-heading {
    font-size: 2.4rem;
    text-align: center;
}

.not-found-btn-container {
    text-align: center;
    padding-top: 100px;
}
