@import "ui.comp.css";
@import "header.comp.css";
@import "404.page.css";
@import "home.page.css";
@import "login.page.css";
@import "pass_reset.page.css";
@import "quiz_result.page.css";
@import "quiz_time.page.css";
@import "register.page.css";
@import "settings.page.css";
@import "splash.page.css";
@import "stats.page.css";
@import "payment.page.css";
@import "progress.comp.scss";
@import "balloons";
@import "common.css";

@import "dqindex_home.page.scss";
@import "dqindex_assess.page.scss";

@font-face {
	font-family: 'Varela';
	font-weight: normal;
	src: url('../fonts/Varela-Regular.ttf') ;
}

@font-face {
	font-family: 'Raleway';
	font-weight: normal;
	src: url('../fonts/Raleway-Regular.ttf') ;
}
@font-face {
	font-family: 'RalewayBold';
	font-weight: bold;
	src: url('../fonts/Raleway-Bold.ttf') ;
}
@font-face {
	font-family: 'RalewayBolder';
	font-weight: bolder;
	src: url('../fonts/Raleway-ExtraBold.ttf') ;
}
@font-face {
	font-family: 'RalewaySemiBold';
	font-weight: 'semibold';
	src: url('../fonts/Raleway-SemiBold.ttf') ;
}
@font-face {
	font-family: 'RalewayLight';
	font-weight: lighter;
	src: url('../fonts/Raleway-Light.ttf') ;
}

@font-face {
	font-family: 'BaiJamj';
	font-weight: normal;
	src: url('../fonts/BaiJamjuree-Regular.ttf') ;
}


@font-face {
	font-family: 'Lemon/Milk';
	font-weight: normal;
	src: url('../fonts/LemonMilk.woff') ;
}
/*
@font-face {
	font-family: 'Raleway';
	font-weight: 'normal';
	src: url('../fonts/Raleway-Regular-.otf') format('opentype');
}
@font-face {
	font-family: 'RalewayBold';
	font-weight: 'bold';
	src: url('../fonts/Raleway-Bold.otf') format('opentype');
}
@font-face {
	font-family: 'RalewayBolder';
	font-weight: 'bolder';
	src: url('../fonts/Raleway-Ultrabold.otf') format('opentype');
}
*/
@font-face {
	font-family: 'Helvetica Neue';
	font-weight: 'normal';
	src: url('../fonts/Helvetica.ttf') format('ttf');
}

@font-face {
	font-family: 'Lemon/Milk';
	font-weight: bold;
	src: url('../fonts/LemonMilkbold.woff');
}

@font-face {
	font-family: 'Lemon/Milk';
	font-weight: 200;
	src: url('../fonts/LemonMilklight.woff');
}

@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: normal;
	src: local('Source Sans Pro'), url('../fonts/SourceSansPro-Regular.otf.woff') format('woff');
}

@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: bold;
	src: local('Source Sans Pro Bold'), url('../fonts/SourceSansPro-Bold.otf.woff') format('woff');
}

@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 500;
	src: local('Source Sans Pro Semi-Bold'), url('../fonts/SourceSansPro-Semibold.otf.woff') format('woff');
}

/*@font-face {*/
/*	font-family: 'Lemon/Milk/Bold';*/
/*	src: url('../assets/fonts/LemonMilkbold.woff');*/
/*}*/


/*@font-face {*/
/*	font-family: 'Lemon/Milk/Light';*/
/*	src: url('../assets/fonts/LemonMilklight.woff');*/
/*}*/

html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #ffffff;
	font-family: 'Lemon/Milk', 'Helvetica Neue', arial, sans-serif;
	font-weight: 400;
	color: #444;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
}

* {
	box-sizing: border-box;
}

* {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

*::-webkit-scrollbar {
	display: none; /* Chrome, Safari, Opera*/
}

#app {
	height: 100%;
}

