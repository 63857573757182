.quiz-time-main-container {
    display: flex;
    flex-direction: column;
    padding: 56px 0;
    min-height: 100vh;
    width: 100%;
}

.quiz-time-progress-container {
    position: relative;
    margin: 0 auto;
    background-color: #ddd;
    border-radius: 5px;
    width: 60vh;
    height: 3vh;
    margin-top: 3vh;
}

.quiz-time-progress-container p {
    position: absolute;
    left: 104%;
    margin: 0;
    font-size: 1.8em;
    line-height: 1em;
}

.quiz-time-progress-img {
    position: absolute;
    left: 0%;
    height: 7vh;
    top: -100%;
    /* z-index: 99; */
}

.quiz-time-progress-bar {
    position: absolute;
    height: 100%;
    width: 0%;
    border-radius: 5px;
}

.qt-question-num-total {
    color: #ddd;
}

.qt-guide-container {
    padding-top: 20px;
    min-height: 35vh;
    text-align: center;
}

.qt-guide-heading {
    font-size: 2.7rem;

    float: right;
    width: 50%;
    margin-top: 7%;
    text-align: left;

    &:lang(vi) {
        font-weight: 600;
        font-family: "Source Sans Pro", "Helvetica Neue";
        text-transform: uppercase;
    }
}

.qt-guide-img-container {
    float: left;
    width: 40%;
    margin-top: 5%;
    display: flex;
    justify-content: flex-end;
}

.qt-guide-img {
    height: 40vh;
    margin: 30px 20px;
}

.qt-guide-body {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: "Source Sans Pro", "Helvetica Neue";

    float: right;
    width: 50%;
    text-align: justify;
    padding-right: 20%;
    margin: 2vh 0 6vh;
}

.qt-cont-btn-container {
    display: flex;
    justify-content: flex-start;
    float: right;
    width: 100%;
    padding-right: 25%;
    margin-right: -1%;
    margin-top: 30px;
    /*padding-right: 25%;*/
}

.qt-cont-btn-container button {
    border-style: solid !important;
}

.qt-tooltip {
    position: relative;
    font-weight: 900;
}
.qt-tooltip-content {
    position: absolute;
    padding: 1vh 2vh;
    margin-right: 2vh;
    border: 1px solid #fff;
    border-radius: 15px;
    font-size: 1rem;
    font-weight: normal;
    color: #FFFFFF;
    min-width: 20vh;
    overflow: visible;
    z-index: 100;
    font-family: "Source Sans Pro", "Helvetica Neue";
}
.qt-question-container {
    position: relative;
    height: 35vh;
    overflow-y: scroll;
    margin-top: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    padding-top: 4vh;
    width: 77%;
    margin: 0 auto;
}

.qt-question-content {
    /* position: absolute;
    bottom: 0; */
    /*font-family: "Source Sans Pro", "Helvetica Neue";*/
    font-weight: bold;
    font-size: 1.9rem;
    padding: 0 20px;
    width: 100%;
}

.qt-font-6 { 
    font-size: 1.75rem; 
}

.qt-question-body {

}

.qt-question-tips {
    /* font-size: 1.6rem; */
    margin-top: 5px;
}

.stb-font-2, .stb-font-3 { font-size: 90%; }
.stb-font-4 { font-size: 85% }
.stb-font-5, .stb-font-6 { font-size: 80%; }

.qt-answer-container-lsq{
    position: fixed;
    left: 15%;
    top: 50%;
    width: 65%;
    padding-bottom: 4vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: start;
    overflow-x: scroll;
    font-family: "Source Sans Pro", "Helvetica Neue";

}
.qt-answer-container-nus{
    position: fixed;
    left: 15%;
    top: 50%;
    width: 65%;
    padding-bottom: 4vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: start;
    overflow-x: visible;
    font-family: "Varela";

}
.qt-answer-container {
    position: fixed;
    left: 15%;
    bottom: 10%;
    /*background: #f5ecdf;*/
    height: 42%;
    width: 70%;
    padding-bottom: 4vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-around; */
    /*place-items: center;*/
    justify-content: center;
    align-items: center;
    align-content: start;
    overflow-y: scroll;

    background:
		/* Shadow covers */
		linear-gradient(white 30%, rgba(255,255,255,0)),
		linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
		
		/* Shadows */
		radial-gradient(50% 0, farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)),
		radial-gradient(50% 100%,farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
	background:
		/* Shadow covers */
		linear-gradient(white 30%, rgba(255,255,255,0)),
		linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
		
		/* Shadows */
		radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
		radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
	background-repeat: no-repeat;
	background-color: white;
	background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
	
	/* Opera doesn’t support this in the shorthand */
	background-attachment: local, local, scroll, scroll;
}

.qt-answer-container:after{
    content: "";
    box-shadow: white 0px 30px 40px -2em inset;
}

.qt-theme-nus_theme {
    bottom: 2%;
    height: 50%;
}

.qt-answer-span {
    cursor: pointer;
    font-size: 1.1rem;
    margin: 20px 15px 0;
    padding: 10px;
    min-width: 20%;
    border-radius: .6rem;
    text-align: center;
    align-self: stretch;
}

.qt-maq.qt-opt-3 .qt-answer-span {
    width: 100%;
}

.qt-maq.qt-opt-4 .qt-answer-span {
    width: 45%;
    min-height: 8vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*.qt-maq.qt-opt-5 .qt-answer-span {*/
/*    width: 55%;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/

.qt-mcq.qt-opt-3 .qt-answer-span,
.qt-mcq.qt-opt-4 .qt-answer-span,
.qt-mcq.qt-opt-5 .qt-answer-span,
.qt-mcq.qt-opt-6 .qt-answer-span,
.qt-mcq.qt-opt-7 .qt-answer-span,
.qt-mcq.qt-opt-8 .qt-answer-span,
.qt-mcq.qt-opt-9 .qt-answer-span,
.qt-mcq.qt-opt-10 .qt-answer-span,
.qt-mcq.qt-opt-11 .qt-answer-span,
.qt-mcq.qt-opt-12 .qt-answer-span,
.qt-maq.qt-opt-3 .qt-answer-span,
.qt-maq.qt-opt-4 .qt-answer-span,
.qt-maq.qt-opt-5 .qt-answer-span,
.qt-maq.qt-opt-6 .qt-answer-span,
.qt-maq.qt-opt-7 .qt-answer-span,
.qt-maq.qt-opt-8 .qt-answer-span,
.qt-maq.qt-opt-9 .qt-answer-span,
.qt-maq.qt-opt-10 .qt-answer-span,
.qt-maq.qt-opt-11 .qt-answer-span,
.qt-maq.qt-opt-12 .qt-answer-span{
    display: flex;
    width: 40%;
    /*height: 8vh;*/
    align-items: center;
    justify-content: center;
}

/*.qt-mcq.qt-opt-3 .qt-answer-span,*/
/*.qt-mcq.qt-opt-4 .qt-answer-span*/
/* {*/
/*    width: 60%;*/
/*}*/

.qt-lsq, .qt-lsq-nus {
    /* width: 80%; */
    left: 15%;
}

.qt-lsq-7 {
    width: 90%;
    left: 5%;
}

.qt-tfq {
    display: flex;
    align-content: center;
}

.qt-ynq .qt-answer-span {
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.qt-lsq .qt-answer-span, .qt-lsq-nus .qt-answer-span  {
    /*height: 8vh;*/
    width: 16%;
    min-width: 16%;
    margin: 20px 10px 0;
    flex-flow: wrap;
    display: flex;
    align-content: center;
    justify-content: center;
}

.qt-lsq-7 .qt-answer-span {
    width: 9%;
    min-width: 11%;
}

.qt-white-mask-top {
    position: fixed;
    top: 40%;
    height: 2.5vh;
    width: 100%;
    background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
}


.qt-white-mask-bot {
    position: fixed;
    bottom: 1.95%;
    height: 4vh;
    width: 100%;
    background: -moz-linear-gradient(bottom,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}

.qt-slider-container {
    width: 90%;
}

.qt-slider-section {
    position: relative;
    margin: 0 auto;
    background-color: #ddd;
    border-radius: 5px;
    height: 5vh;
}

.qt-slider-img {
    position: absolute;
    left: 0%;
    height: 12vh;
    top: -72%;
}

.qt-slider-answers {
    display: inline-table;
    width: 100%;
}

.qt-slider-answer-span {
    display: table-cell;
    width: 13%;
    text-align: center;
    font-family: 'Lemon/Milk', 'Helvetica Neue', arial, sans-serif;
    font-size: 1.2em;
    padding: 4vh 0;
    color: #c7c7ce;
    cursor: pointer;
    &:lang(vi) {
        font-family: "Source Sans Pro","Helvetica Neue";
    }
}


.qt-next-btn, .qt-prev-btn {
    position: fixed;
    width: 9vh;
    height: 18vh;
    bottom: 44vh;
    cursor: pointer;
    display: flex;
    align-items: center;
    z-index: 99;
}

.qt-next-btn {
    right: 0;
    border-top-left-radius: 9vh;
    border-bottom-left-radius: 9vh;
}

.qt-next-btn-disable {
    background-color: #c1beb9;
}

.qt-next-btn-img {
    position: absolute;
    left: 50%;
    height: 6vh;
}

.qt-prev-btn {
    left: 0;
    border-top-right-radius: 9vh;
    border-bottom-right-radius: 9vh;
}

.qt-prev-btn-img {
    position: absolute;
    left: 20%;
    height: 6vh;
}

.balloon_game_start_btn_container {
    bottom: 33%;
    position: fixed;
    text-align: center;
    width: 100%;
}

.balloon_game_start_btn {
    height: 10vh;
    width: 30vw;
    margin-left: 32vw;
    border-radius: 12px;
}

.balloon_game_start_btn_img {
    position: absolute;
    top: 22%;
    left: 45%;
    height: 6vh;
}

.digital-wellbeing-container{
    display: flex;
    flex-direction: row ;
    padding: 10px;
    margin: 50px;
}

.digital-wellbeing-score {
    background-color: #EDEDED;
    padding: 0 50px;
    height: 60%;
    min-width: 50%;
    position: relative;
    border-radius: 20px;
}
.digital-wellbeing-score .score-label-pt {
    vertical-align: middle !important;
}

.digital-wellbeing-score-spinner {
    font-size: 2.9em;
    font-weight: bold;
    min-height: 14vh;
    line-height: 4.5em;
    position: absolute;
    top: -32px;
    right: -30px;
    width: 4.5em;
    height: 4.5em;
}

.digital-wellbeing-score-header{
    display: flex;
    flex-direction: column;
}
.header-column {
    display: flex;
    flex-direction: row;
    font-family: "Varela";
}
.header-column h2{
    line-height: 48px;
    text-align: left;
    font-size: 2.6em;

}
.divider {
    border-top: 5px solid #ED2E82;
    margin-top: -24px;
}
.digital-wellbeing-type {
    display: flex;
    flex-direction: column;
    font-family: "BaiJamj";
}
.dw-score-label {
    text-align: left;
    margin: 20px 0;
    width: 85%;
}

.digital-type {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.digital-type h3{
    width: 135px;
    text-align: left;
    font-size: 1.3em;
    font-weight: bolder;
    cursor: pointer;
    margin: 0;
}
.digital-type h3:hover {
    text-decoration: underline 2px;
}

.type-1 h3{
    color: #963bab;
}
.type-2 h3{
    color: #3b5eab;
}
.type-3 h3{
    color: #05b5c7;
}
.type-4 h3{
    color: #8ac440;
}.type-5 h3{
     color: #ED2E82;
 }
.circle {
    width: 4vw;
    height: 4vw;
    padding: 16px;
    border-radius: 50%;
    background-color: #fff;
    margin: 5px 20px 5px 0;
}
.circle img{
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}
.type-1 .circle{
    border: 2px solid #963bab;
}
.type-2 .circle{
    border: 2px solid #3b5eab;
}
.type-3 .circle{
    border: 2px solid #05b5c7;
}
.type-4 .circle{
    border: 2px solid #8ac440;
}.type-5 .circle{
     border: 2px solid #ED2E82;
 }

.range {
    margin-left: 30px;
    flex-grow: 1;
    padding: 18px;
    border-radius: 15px;
    position: relative;
    background-color: white;
}
.range-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.percentage {
    position: absolute;
    top: 0;
    padding: 10px;
    left: 0;
    height: 100%;
    text-align: right;
    border-radius: 15px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    color: white;
    min-width: 9%;
}

.type-default {
    position: absolute;
    width: 100%;
    font-size: 14px;
    padding: 10px;
    top: 0;
    left: 0;
}

.type1 .percentage{
    background-color: #963bab;
}
.type2 .percentage{
    background-color: #3b5eab;
}
.type3 .percentage{
    background-color: #05b5c7;
}
.type4 .percentage{
    background-color: #8ac440;
}
.type5 .percentage{
    background-color: #ED2E82;
}

.digital-wellbeing-score-desc p{
    text-align: center;
    font-size: 1em;
    font-family: "BaiJamj";
    font-weight: 500;
    margin-top: 20px;
    padding-bottom: 20px;
    color: #676767;
}
.digital-wellbeing-type-content{
    min-width: 50%;
    padding: 0 40px;
}

.digital-wellbeing-type-content h2{
    font-size:2.5em;
    margin-top: 45px;
    color: #05b5c7;
    font-family: "Varela";
    font-weight: bolder;
}

.digital-wellbeing-type-content p{
    margin-top: 45px;
    text-align: left;
    padding: 0 35px;
    font-size: 1.2em;
    font-family: "BaiJamj";
    font-weight: 500;
}

.digital-wellbeing-button{
    background-color: #ED2E82;
    color: white;
    border-radius: 19px;
    width:210px;
    font-size: 2em;
    padding: 6px 0;
    margin: 40px auto 10px;
    font-family: "BaiJamj";
    font-weight: 500;
    cursor: pointer;
}
.digital-wellbeing-retake-button {
    border: 2px solid #ED2E82;
    color: #ED2E82;
    border-radius: 19px;
    width: 210px;
    font-size: 1.6em;
    padding: 6px 0;
    margin: 10px auto 30px;
    font-family: "BaiJamj";
    font-weight: 500;
    cursor: pointer;
}
.retake-icon {
    padding-right: 10px;
}

.digital-wellbeing-type-desc{
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    font-family: "BaiJamj";
    font-weight: 500;
}

.wellbeing-type{
    font-size: 3.8em;
    padding: 10%;
    margin-top: 7vh;
    min-width: 25vh;
    min-height: 25vh;
    line-height: 16vh;
}

.popup{
    position: absolute;
    left: 40%;
    width: 43%;
    z-index: 4;
    padding: 15px 25px;
    background-color: white;
    text-align: left;
    border-radius: 15px;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
    font-family: "BaiJamj";
    font-weight: 500;
}
.popup-button{
    border-radius: 10px;
    cursor: pointer;
    border: none;
    color: white;
    padding: 10px;
    position: relative;
    left: 28%;
}
.popup-button a {
    text-decoration: none;
    color: #fff;
}
.popup-close {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 2px 6px;
    color: #676767;
    font-size: 13px;
    cursor: pointer;
}
.type-1 .popup-button{
    background-color: #963bab;
}
.type-2 .popup-button{
    background-color: #3b5eab;
}
.type-3 .popup-button{
    background-color: #05b5c7;
}
.type-4 .popup-button{
    background-color: #8ac440;
}
.type-5 .popup-button{
    background-color: #ED2E82;
}

.type-1 .popup{
    color: #963bab;
    top: 20%;
}
.type-2 .popup{
    color: #3b5eab;
    top: 35%;
}
.type-3 .popup{
    color: #05b5c7;
    top: 47%;
}
.type-4 .popup{
    color: #8ac440;
    top: 60%;
}
.type-5 .popup{
    color: #ED2E82;
    top: 73%;
}

.digital-wellbeing-type .type-1 .circle { background: #fff url(../icons/NUS_Icons/VectorSmartObject-1.png) no-repeat center center; background-size: 2.5vw; }
.digital-wellbeing-type .type-2 .circle { background: #fff url(../icons/NUS_Icons/VectorSmartObject-2.png) no-repeat center center; background-size: 2.5vw; }
.digital-wellbeing-type .type-3 .circle { background: #fff url(../icons/NUS_Icons/VectorSmartObject-3.png) no-repeat center center; background-size: 2.5vw; }
.digital-wellbeing-type .type-4 .circle { background: #fff url(../icons/NUS_Icons/VectorSmartObject-4.png) no-repeat center center; background-size: 2.5vw; }
.digital-wellbeing-type .type-5 .circle { background: #fff url(../icons/NUS_Icons/VectorSmartObject-5.png) no-repeat center center; background-size: 2.5vw; }

.digital-wellbeing-type-content .P1 { background: url(../icons/NUS_Icons/Digital-Novice.png) no-repeat center center; background-size: contain; }
.digital-wellbeing-type-content .P2 { background: url(../icons/NUS_Icons/Tech-Explorer.png) no-repeat center center; background-size: contain; }
.digital-wellbeing-type-content .P3 { background: url(../icons/NUS_Icons/Digital-Adverturer.png) no-repeat center center; background-size: contain; }
.digital-wellbeing-type-content .P4 { background: url(../icons/NUS_Icons/Screen-Sorcerer.png) no-repeat center center; background-size: contain; }
.digital-wellbeing-type-content .P5 { background: url(../icons/NUS_Icons/Wellbeing-Whiz.png) no-repeat center center; background-size: contain; }


@media only screen and (max-width: 768px) {
    .quiz-time-main-container{
       min-height: auto;
    }
    .qt-lsq-nus {
        width: 100%;
        left: 5%;
    }
.popup{
    width: 57%;
    left: 35%;
}
    .digital-wellbeing-container {
        flex-direction: column;
        margin: 70px 0 0 0;
        padding: 0;
    }
    .digital-wellbeing-score {
        padding: 0 20px;
    }
    .circle{
        width: 8vw;
        margin-right: 10px;
        background-size: 5vw !important;
    }
    .range{
        margin: 10px 0 10px 10px;
        padding: 10px;
    }
    .percentage {
        min-width: 18%;
    }
    .percentage, .type-default {
        font-size: 12px;
        padding: 3px;
    }

    .digital-type h3{
        font-size: .8em;
        width: 85px;
    }
    .header-column h2{
        font-size: 1.6em;
        line-height: 35px;
    }
    .divider {
        border-width: 3px !important;
        margin-top: -14px;
    }
    .digital-wellbeing-score-spinner {
        top: -17px;
        right: -20px;
    }
    .digital-wellbeing-score-spinner {
        font-size: 1.8em;
        width: 4.1em;
    }
    .dw-score-label {
        width: 100%;
    }
    .digital-wellbeing-type-content {
        padding: 30px;
    }
    .digital-wellbeing-type-content h2 {
        font-size: 1.3em;
    }
    .digital-wellbeing-type-content p {
        padding: 0;
        margin: 25px 0;
        font-size: 1em;
    }
    .wellbeing-type {
        margin-top: 5vh;
        width: 75%;
        margin: 0 auto;
    }
    .popup-button {
        left: 0;
    }

    .qt-slider-img {
        height: 9vh;
        top: -43%;
    }

    .quiz-time-progress-container {
        width: 80%;
    }

    .quiz-time-progress-container p {
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        text-align: center;
        margin-top: 1vh;
        font-size: 1.2em;
    }

    .qt-question-container {
        width: 100%;
    }

    .qt-answer-container {
        left: 10%;
        width: 80%;
    }

    .qt-question-content {
        font-size: 1.3rem;
    }

    .qt-answer-span {
        margin: 14px 20px 0;
        min-width: 40%;
        width: 100%;
    }
    
    .qt-maq.qt-opt-4 .qt-answer-span, .qt-maq.qt-opt-5 .qt-answer-span, 
    .qt-maq.qt-opt-6 .qt-answer-span, .qt-maq.qt-opt-7 .qt-answer-span,
    .qt-maq.qt-opt-8 .qt-answer-span, .qt-maq.qt-opt-9 .qt-answer-span, 
    .qt-maq.qt-opt-10 .qt-answer-span, .qt-maq.qt-opt-11 .qt-answer-span, 
    .qt-maq.qt-opt-12 .qt-answer-span, .qt-mcq.qt-opt-3 .qt-answer-span,
    .qt-mcq.qt-opt-4 .qt-answer-span, .qt-mcq.qt-opt-5 .qt-answer-span {
        width: 100%;
    }

    .qt-font-1 { font-size: 1.4rem; }
    .qt-font-2 { font-size: 1.3rem; }
    .qt-font-3 { font-size: 1.2rem; }
    .qt-font-4 { font-size: 1.1rem; }
    .qt-font-5, .qt-font-6 { font-size: 0.9rem; }
    .qt-font-7 { font-size: .8rem; }

    .qt-lsq .qt-answer-span {
        width: inherit;
        height: initial;
    }

    .qt-slider-answer-span {
        font-size: 1.2em;
    }

    .qt-next-btn, .qt-prev-btn {
        width: 10vh;
        height: 9vh;
        bottom: 0;
    }

    .qt-next-btn {
        border-top-left-radius: 100%;
        border-bottom-left-radius: 0;
    }

    .qt-prev-btn {
        border-top-right-radius: 100%;
        border-bottom-right-radius: 0;
    }

    .qt-next-btn-img {
        left: 55%;
        top: 30%;
        height: 5vh;
    }

    .qt-prev-btn-img {
        top: 30%;
        height: 5vh;
    }

    .qt-guide-img-container {
        /*min-height: 50vh;*/
    }

    .qt-guide-heading {
        font-size: 1.7rem;
    }

    .qt-guide-img-container, .qt-cont-btn-container {
        justify-content: center;
    }

    .qt-guide-img {
        height: 24vh;
        margin-bottom: 20px;
    }

    .qt-guide-body {
        font-size: 1.1rem;
        margin: 0 4vh 6vh;
    }

    .qt-guide-heading, .qt-guide-img-container, .qt-guide-body, .qt-cont-btn-container {
        float: unset;
        width: auto;
        margin-top: auto;
        padding-right: unset;
        text-align: center;
    }
    
}
