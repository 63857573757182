.static-page-container {
    padding: 50px 17vw;
    font-family: "Source Sans Pro", "Helvetica Neue";
}

.static-page-container .dq-logo {
    height: 6rem;
}

.static-page-title-text {
    font-size: 2.4rem;
    text-align: center;
    color: #f8b243;
    font-weight: 500;
    text-shadow: 1px 1px 2px #ddd;
}

.static-page-section {
    margin-bottom: 2.5rem;
    user-select: text;
}

.static-page-section-title {
    margin-bottom: 1.2rem;
    margin-top: 2rem;
    font-size: 1.4rem;
    font-weight: 450;
}

.static-page-section-content {
    margin-top: .9rem;
    margin-bottom: .9rem;
    font-size: 1.1rem;
    text-align: justify;
}

.cookie-table {
    border: 1px solid #000;
    border-collapse: collapse;
    border-spacing: 5px;
    width: 100%;
}
.cookie-table td, .cookie-table th {
    border: 1px solid #000;
    border-collapse: collapse;
    vertical-align: top;
    padding: 5px;
}
.cookie-lbl {
    color: #2d4b62;
    font-weight: 500;
}

@media only screen and (max-width: 768px) {
    .static-page-container {
        padding: 50px 20px;
    }
    .static-page-container .dq-logo {
        display: block;
        margin: 0 auto;
        margin-bottom: 15px;
    }
    .cookie-table, .cookie-table th, .cookie-table td { 
		display: block; 
	}
    .cookie-table thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	.cookie-table td ul {
        padding-left: 15px;
    }
	.cookie-table td { 
		border: none;
		border-top: 1px solid #2d4b62; 
		position: relative;
		padding-left: 36%; 
	}
	.cookie-table td:before { 
		position: absolute;
        font-weight: 500;
		top: 6px;
		left: 6px;
		width: 35%; 
		padding-right: 10px;
	}
    .cookie-table td:nth-of-type(1) { 
        background-color: #2d4b62; 
        padding-top: 6px;
        padding-bottom: 8px;
        color: #fff;
    }
    .cookie-table td:nth-of-type(1):before { content: "Type of Cookie"; }
	.cookie-table td:nth-of-type(2):before { content: "Source"; }
	.cookie-table td:nth-of-type(3):before { content: "What these cookies do"; }
	.cookie-table td:nth-of-type(4):before { content: "How to exercise our Cookie choice"; }
}