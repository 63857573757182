.container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.toast {
    position: fixed;
    bottom: 0;
    width: auto;
    background: #1d1c1c;
    margin: 30px auto;
    z-index: 9999;
    /* border-radius: 8px; */
    width: 100%;
    font-family: 'Source Sans Pro';
}

.toast_show {
    display: block;
    opacity: 0.5;
    transition: opacity 0.5s ease-out;
}

.toast_hide {
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease-in;
}

.toast_text {
    color: azure;
    margin: 10px 20px;
    text-align: center;
    font-size: 1rem;
}

.simple-btn {
    margin: 0px 15px;
    width: 100%;
    border-radius: 6px;
    color: white;
    border: 0px solid #ffffff;
    font-family: 'Lemon/Milk', 'Helvetica Neue', arial, sans-serif;
    &:lang(vi) {
        font-family: "Source Sans Pro","Helvetica Neue";
    }
}

.simple-btn-sm {
    padding: 9px 10px;
    font-size: .75rem;
    &:lang(vi) {
        font-size: .9rem;
    }
}

.simple-btn-md {
    padding: 8px 24px;
    font-size: 1.6rem;
}

.simple-btn-lg {
    padding: 10px 30px;
    font-size: 2rem;
}


.border-btn-sm {
    padding: 6px 18px;
    font-size: 1.2rem;
}

.border-btn-md {
    padding: 10px;
    width: 50% !important;
    font-size: 1rem;
    font-family: 'Lemon/Milk', 'Helvetica Neue', arial, sans-serif;
    &:lang(vi) {
        font-family: "Source Sans Pro","Helvetica Neue";
    }
}

.border-btn-lg {
    padding: 16px 54px;
    font-size: 2rem;
}

.border-btn-ui {
    width: auto;
    border-radius: 10px;
    background-color: transparent;
    font-weight: bolder;
    cursor: pointer;
}

/*.border-btn-ui:hover {*/
/*    background: #f8b143;*/
/*    color: #fff;*/
/*}*/

.media-share-section {
    display: table-cell;
    padding-right: 20px;
    
    display: none;
}

.media-share-section div {
    font-size: .75em;
    padding-bottom: 3px;
}

.media-share-section span {
    display: inline-block;
    border-radius: 50%;
    padding: 13px;
    color: #fff;
    cursor: pointer;
    background-size: 60%;
    margin: 0 2px;
}

.facebook { background: url("../icons/media/facebook-128.png") no-repeat center center;}
.twitter { background: url("../icons/media/twitter-128.png") no-repeat center center;}
.instagram { background: url("../icons/media/instagram-128.png") no-repeat center center;}
.tumblr { background: url("../icons/media/tumblr-128.png") no-repeat center center;}