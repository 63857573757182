.login-container {
    margin-top: 13vh;
    justify-content: center;
        text-align: center;
        min-height: 100%;
        width: 100%;
        font-family: 'Source Sans Pro';
}

.icon-button{
    position: relative;
    float: right;
    top:3;
    border: none;
    color: #001F3D;
    background: none;
}
.login-container .dq-logo-image {
    text-align: center;
}

.login-container .dq-logo-image img {
    height: 15vh !important;
    /* width: 15vw !important; */
    margin: 1vh auto;
}

.dq-demographic .dq-logo-image img {
    height: 170px;
    padding-bottom: 30px;
}

.dq-logo-image .logo-nus_theme {
    height: 125px !important;
}

.dq-login-content {
    text-align: center;
    margin: 2vh;
}

.dq-login-txt {
    padding: 1vh;
    margin: .5vh;
    text-align: center;
    font-size: 1rem;
    ;
    color: gray;
    border-radius: 10px;
    border: 1px solid gray;
    min-width: 250px;
}

.login-container .dq-options-submit {
    margin-top: 2vh;
}

.login-container .dq-options-submit button {
    min-width: 250px;
    color: #066f7f ;
    border: 2px solid #078497;
}

.login-container .forgot-password {
    margin-top: 1vh;
}

.login-container .forgot-password a {
    color: #066f7f;
    font-size: 1.1rem;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.google-login {
    margin-top: 3vh;
}

.google-login button {
    transition: background-color 100ms, box-shadow 100ms;

    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;
    cursor: pointer;

}

.google-login button:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.google-login button:focus {
    outline: none;
    box-shadow:
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
}

@media only screen and (max-width: 768px) {
    .login-container{
        left: 3% !important;
        width: 93%;
    }
    .register-container{
        width: 90% !important;
        left: 6% !important;
    }
}

