.switch {
    position: relative;
    display: inline-block;
    width: 75px;
    height: 35px;
    padding:10px ;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 35px;
    width: 35px;
    left: 4px;
    bottom: 0px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(35px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}




.register-container {
    padding-top: 10vh;
    margin: 0 auto;
    min-height: 100%;
    max-width: 400px;
    text-align: center;
    font-family: 'Source Sans Pro';
}

.dq-demographic {
    padding-top: 12vh;
}

.register-container .dq-logo-image {
    padding-top: 4vh;
}

.register-container .dq-logo-image img {
    height: 12vh;
    margin: 1vh auto;
}

.register-container label {
    display: block;
    color: #066f7f;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 1vh;
    padding-left: .75vh;
    text-align: left;
}

.dq-register-row {
    width: 37vh;
    min-width: 250px;
    max-width: 325px;
    margin: 0 auto;
}

.dq-register-txt {
    border-radius: 10px;
    border: 1px solid gray;
    width: 100%;
    padding: .9vh;
    color: gray;
    font-size: 1rem;
}

.dq-register-sub-lbl {
    padding-left: .75vh;
    text-align: left;
    font-style: italic;
    font-size: .8rem;
    color: gray;
}

.dq-register-terms {
    width: 37vh;
    margin: 0 auto;
    margin-top: 3vh;
    color: gray;
    font-weight: 500;
    text-align: left;
    display: flex;
    align-items: center;
}

.dq-term-col1 {
    padding-right: 1vh;
}
.dq-term-col1 input {
    width: 2.5vh;
    height: 2.5vh;
    margin: 0;
}

.dq-register-terms a {
    color: #066f7f;
    font-size: .9rem;
    font-weight: 500;
    text-decoration: underline;
}

.register-container .dq-options-submit button {
    margin-top: 1vh !important;
    padding: 1.3vh 7vh;
}

.dq-demographic .dq-options-submit button {
    padding: 1.3vh 4vh;
    min-width: 28vh;
}

.dq-select {
    position: relative;
}
.dq-select select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 1vh;
}

.dq-select::after {
    content: "\25bc";
    color: #066f7f;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 1vh;
    pointer-events: none;
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 1.2rem;
}
