.stripe-label {
    color: dimgray;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 2px;
    padding-left: 0;
    font-weight: lighter;
    text-align: left;
}

.stripe-text-field-container {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
}

.stripe-text-field {
    flex: 1;
    border: lightgray solid 1px;
    padding: 12px;
    margin: 0 0 0 0;
    border-radius: 5px;
    font-size: 16px;
}

.stripe-text-field:focus {
    outline: hsl(210deg 96% 45% / 25%) solid 3px;
}

#apply-voucher {
    padding: 10px;
    background-color: rgba(253, 135, 21, 0.75);
    border-radius: 5px;
    cursor: pointer;
}