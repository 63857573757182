.splash-container {
    margin-top: 20vh;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 40vh;
}

.splash-heading {
    font-size: 2.4rem;
    text-align: center;
}

.backdrop-loading-container {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    opacity: 0.5;
    z-index: 5000
}
.nus-loading-container {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000
}
.nus-backdrop-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: black;
    opacity: 0.6;
  }

.backdrop-loading-container-darker {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    opacity: 0.7;
    z-index: 5000
}